@use "../utilities/color" as c;

.fc-theme-standard {
  flex-shrink: 1;
}

.fc .fc-scroller-liquid-absolute {
  scrollbar-width: thin;
  scrollbar-color: c.color(grey);

  &::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: c.color(grey, xlt); /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: c.color(grey); /* color of the scroll thumb */
  }
}
