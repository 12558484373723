@use "../utilities/color" as c;

#page-VendorInvoiceReview {
  #btn-RefreshWorkTickets {
    padding-top: 4px !important;
  }
  .InstallmentRow--current {
    // background-color: c.color(yellow, xlt);
    .ant-tag {
      // border: 1px solid c.color(grey, lt);
      // color: c.color(grey, lt);
      background-color: transparent;
      text-transform: uppercase;
      margin-left: 10px;
      display: inline-flex;
      align-items: center;
      .anticon {
        margin-right: 5px;
      }
    }
  }
  .ant-space-item {
    &:first-child {
      .InstallmentInvoice--current .ant-tag {
        margin-left: 0;
      }
    }
  }
}
