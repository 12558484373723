@use "../utilities/color" as c;

.ViewDetails {
  .ant-statistic-title {
    margin-bottom: 0;
    color: c.color(grey);
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.05em;
    line-height: 1em;
    text-transform: uppercase;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .ant-statistic-content {
    font-size: 20px;
  }
  .ValueDetails--linked {
    cursor: pointer;
    color: c.color(green);
    &:hover {
      color: c.color(green, lt);
    }
  }
  .ant-statistic-content {
    span,
    a {
      display: flex;
      align-items: center;
    }
  }
  .ant-btn-icon-only.ant-btn-lg {
    // padding: 0;
    height: auto;
  }
}

.ViewDetails-divider,
.ViewDetails-rangeDivider {
  font-size: 15px;
  margin: 0 7px;
  color: c.color(grey, lt);
}
