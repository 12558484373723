button,
a {
  &.ant-btn {
    // padding-top: 4px !important;
    &,
    span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}
