.proposal-list-row {
  cursor: pointer;
}

.proposal-table, .proposal-table:hover {
  background-color: lightgrey;
}

.login-container {
  padding: 15px 50px;
  border-radius: 5px;
  background-color: rgba(28, 255, 179);
}

@media screen and (max-width: 600px) {
  .logo {
    height: 30px;
  }
  .logo-box {
    position: absolute;
    top: 20px;
    left: 20px;
  }
  .login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 85vw;
    text-align: center;
    padding: 15px 25px;

}

}
@media screen and (max-width: 800px) {
  .logo {
    height: 40px;
  }
  .login-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 85vw;
      text-align: center;
      padding: 15px 25px;

  }
  .logo-box {
    position: absolute;
    top: 30px;
    left: 30px;
  }
}
@media screen and (min-width: 801px) {

  .logo {
    height: 50px;
  }
  .login-container {
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%);
      width: 450px;
      text-align: center;
  }
  .logo-box {
    position: absolute;
    top: 40px;
    left: 40px;
  }

}

/* style.backgroundColor = 'white';
style.border = '2px solid'
style.borderRadius = '15px'
} else style.backgroundColor = i % 2 === 0 ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0, 0, 0, 0.025)' */
.proposal-card:not(.active):nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.proposal-card:not(.active):nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.025);
}

.spinner-container {
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.015);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner-inner {
  max-width: 400px;
}