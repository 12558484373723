@use "../utilities/color" as c;
@import "@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/_ag-theme-alpine-mixin.scss";

.ant-table-row {
  position: relative;
}

.ant-table {
  .ant-table-tbody {
    & > tr > td > .ant-table-wrapper:only-child .ant-table {
      margin: 0;
    }
    & > tr.ant-table-expanded-row {
      opacity: 0.85;
      position: relative;
      td {
        // background-color: c.color(grey, xxlt);
        // border-bottom-color: c.color(grey);
        border-bottom: none;
      }
      .ant-table {
        background-color: transparent;
        .ant-table-thead {
          background-color: transparent;
          tr {
            th {
              color: c.color(grey, xxlt);
              border-color: c.color(grey);
              background-color: c.color(grey);
            }
          }
        }
        tr {
          th,
          td {
            font-size: 13px;
            padding: 0.2rem;
          }
          td {
            border-bottom: 1px solid c.color(grey, lt);
          }
        }
      }
      // & > td {
      //   background-color: white;
      // }
      & > td.ant-table-cell {
        background-color: white;
        padding-top: 0;
        padding-right: 20px;
        padding-left: 20px;
        &:first-child:before {
          $size: 8px;
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: $size solid transparent;
          border-right: $size solid transparent;
          border-bottom: $size solid c.color(grey, dk);
          background-color: transparent;
          position: absolute;
          top: -#{$size};
          left: 30px;
        }
      }
      // > .ant-table-wrapper {
      //   margin-bottom: 0;
      //   .ant-table {
      //     margin: 0 10px 8px 10px;
      //   }
      // }
    }
    .ant-table-expanded-row-parent {
      td {
        padding-bottom: 10px;
        // border-bottom-color: c.color(grey);
        border-bottom: none;
      }
    }
    .ant-table-post-expanded-row {
      td {
        // padding-top: 25px;
        border-top: 1px solid c.color(grey, xlt);
      }
    }
    & + .ant-table-row > td {
      padding-top: 20px;
    }
  }
}

.Row--toDelete {
  &:after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-top: 1px solid c.color(red);
  }
  &--withFormFields:after {
    transform: translateY(calc(-50% - 12px)); // Half of field bottom margin
  }
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      header-background-color: c.color(grey, xlt),
      control-panel-background-color: c.color(grey, xlt),
      row-hover-color: c.color(yellow, xlt),
      column-hover-color: c.color(yellow, xlt),
      // active-color: c.color(green),
    )
  );

  .ag-side-bar {
    background-color: c.color(grey, xlt);
  }

  .ag-cell-value,
  .ag-group-value {
    // word-break: break-all;
    white-space: nowrap;
  }

  .ag-row-danger {
    background-color: c.color(red, xxlt);
    border-bottom-color: c.color(red, lt);
  }

  .ag-row-selected::before {
    background-color: c.color(yellow, xlt);
  }
}
