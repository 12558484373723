.WeatherGroup {
  & > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 50%;
    transform: translateY(-50%);
    padding-top: 0;
  }
}

.WeatherModal {
  .WeatherModal-cancelBtn {
    display: none;
  }
}
