.ant-comment {
  .ant-comment-content-author-name {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .ant-comment-content-author-time {
    font-weight: 700;
    padding-right: 0;
  }
  .ant-comment-content {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    font-size: 16px;
  }
  &.align-right .ant-comment-inner {
    justify-content: flex-end;
    .ant-comment-content {
      order: 1;
      align-items: flex-end;
    }
    .ant-comment-avatar {
      order: 2;
      margin-right: 0;
      margin-left: 12px;
    }
  }
}
