#VendorSmsMessageInput {
  .ant-input-group-addon {
    border: none;
  }
  .ant-space-item {
    display: flex;
    align-items: stretch;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
}
