@use "../utilities/color" as c;

#VendorInvoice-form {
  #InvoiceLineItemTable {
    .ant-divider {
      margin: 5px 0 15px;
    }
  }

  .InvoiceLineItem {
    .ant-row {
      margin-bottom: 15px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  #VendorInvoice-totalTable {
    margin-top: -5px;
    // tr:first-child td {
    //   padding-top: 0;
    // }
  }
}
#new-vendor-invoice--input-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
}