// @use "../utilities/color" as c;

.Req {
  display: inline-block;
  margin-right: 4px;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  content: "*";
}
.set-to-delete {
  position: absolute;
  top: calc(50% - 12px);
  transform: translateY(-50%);
  width: 100%;
}

// .ant-form-item-explain-error {
//   color: c.color(grey);
//   letter-spacing: 0.05em;
//   line-height: 1em;
//   text-transform: uppercase;
//   margin-top: 3px;
//   display: flex;
//   align-items: center;
//   gap: 3px;
//   font-weight: 500;
//   font-style: italic;
//   font-size: 11px;
// }