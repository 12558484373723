@use "./utilities/color";
@use "./utilities/show-on-hover";
@use "./components/tables";
@use "./components/button";
@use "./components/pdf-viewer";
@use "./components/weather-item";
@use "./components/modal";
@use "./components/view-details";
@use "./components/form";
@use "./components/comment";
@use "./components/calendar";
@use "./components/image";
@use "./components/react_pdf";
@use "./components/sms";
// @use "./components/multi-record-note";
@use "./components/draggable";
@use "./pages/vendor-invoice-all-views";
@use "./pages/_new-vendor-invoice";
@use "./pages/review-vendor-invoice";
@use "./pages/vendor-payments";
@use "./components/vendor-contact-card";

body {
  margin: 0;
}